import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { withPage } from "../../Page";
import ActionCreator from "../../ActionCreator";
import ArticleListItem from "../../Components/ArticleListItem";
import * as Widget2 from "../../Components/Widget2";

class BlogListPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      blog: null,
      showSpinner: true
    };
  }

  componentDidMount() {
    this._fetchRecords();
  }

  render() {
    let { blog, showSpinner } = this.state;

    return (
      <Wrapper>
        <Widget2.Banner img="/images/banner07.png" css="margin-bottom: 90px;">
          <div className="mist">
            <div className="title">青田悅誌</div>
            <div className="slogan">每天一點衛教知識，養小孩原來那麼容易</div>
          </div>
        </Widget2.Banner>

        <Container>
          <div className="intro">
            <div className="title">知識就是力量！</div>
            <p>
              {
                "由營養師、中醫師與專業證照的月子餐廚師組成「黃金三角」團隊，嚴選每一項食材、中藥材，秉持新鮮多種、多樣，變化每天餐點內容，以符合衛生管理標準的專業認證廚房現煮供應，為您打造健康且健康的月子餐點。"
              }
            </p>
          </div>

          {(() => {
            if (showSpinner) {
              return (
                <Widget2.Center>
                  <Widget2.Spinner />
                </Widget2.Center>
              );
            }

            return (
              <div className="list">
                {blog.results.map((item, i) => (
                  <ArticleListItem
                    key={"blog-list-item" + i}
                    from={"blog"}
                    data={item}
                    css="margin-bottom: 35px;"
                  />
                ))}
              </div>
            );
          })()}
        </Container>
      </Wrapper>
    );
  }

  _fetchRecords = () => {
    let { appActions } = this.props;

    this.setState({ showSpinner: true });
    appActions
      .getBlogList()
      .then(blog => this.setState({ blog }))
      .then(() => this.setState({ showSpinner: false }))
      .catch(err => {
        console.warn(err);
        this.setState({ showSpinner: false });
      });
  };
}

const Wrapper = styled.div`
  padding-top: 100px;
`;

const Container = styled.div`
  margin: 0 auto;
  max-width: 1181px;
  padding: 20px;

  & > .intro {
    margin-bottom: 65px;

    & > .title {
      margin-bottom: 39px;
      text-align: center;
      font-weight: bold;
      font-size: 30px;
      color: #71c6d0;
      letter-spacing: 3px;
    }

    & > p {
      margin-bottom: 40px;
      text-align: center;
      font-size: 16px;
      color: #101d1f;
      letter-spacing: 1.6px;
    }
  }

  & > .list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
  }
`;

export default withPage(
  connect(
    null,
    ActionCreator
  )(BlogListPage)
);
